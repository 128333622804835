/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
    -webkit-appearance: none;
     appearance: none;
     background: transparent;
     cursor: pointer;
     /* width: 15rem; */
 }
 
 /* Removes default focus */
 input[type="range"]:focus {
   outline: none;
 }
 
 /***** Chrome, Safari, Opera and Edge Chromium styles *****/
 /* slider track */
 input[type="range"]::-webkit-slider-runnable-track {
    background-color: rgb(229, 231, 235);
    border-radius: 0.5rem;
    height: 0.5rem;  
 }
 
 /* slider thumb */
 input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -12px; /* Centers thumb on the track */
 
    /*custom styles*/
    background-color: rgb(244, 202, 100);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
 }
 
 input[type="range"]:focus::-webkit-slider-thumb {   
   border: 1px solid rgb(229, 231, 235);
   outline: 1px solid rgb(229, 231, 235);
   outline-offset: 0.125rem; 
 }
 
 /******** Firefox styles ********/
 /* slider track */
 input[type="range"]::-moz-range-track {
    background-color: rgb(229, 231, 235);
    border-radius: 0.5rem;
    height: 0.5rem;
 }
 
 /* slider thumb */
 input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
 
    /*custom styles*/
    background-color: rgb(244, 202, 100);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
 }
 
 input[type="range"]:focus::-moz-range-thumb {
   border: 1px solid rgb(229, 231, 235);
   outline: 1px solid rgb(229, 231, 235);
   outline-offset: 0.125rem; 
 }